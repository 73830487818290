import { environment } from "src/environments/environment";
import { permissionEntity } from "../constants/permission";

export function filterValue(passedArray: any[], passedControl, passedReplaySubject) {
  if (!passedArray) {
    return;
  }
  // get the search keyword
  let search = passedControl.value;

  if (!search) {
    passedReplaySubject.next(passedArray.slice());
    return;
  } else {
    search = search.toLowerCase();
  }
  // filter the banks
  passedReplaySubject.next(
    passedArray.filter(supervisersName => supervisersName?.name?.toLowerCase().indexOf(search) > -1)
  );
}


export function initYear() {
  const currentYear = new Date().getFullYear();
  const startYear = 2005;
  let allYears = [];
  for (let year = startYear; year <= currentYear + 1; year++) {
    allYears.push({ id: year, year: year.toString() });
  }
  return allYears;
}

export function random4DigitId() {
  return Math.floor(1000 + Math.random() * 9000);
}

export function filteredParams(fields: any) {
  let filters = [];
  Object.keys(fields).forEach(key => {
    if (fields[key] != '') {
      let control = {};
      control[key] = fields[key]
      filters.push(control);
    }
  });

  return filters;
}

export function getFiltersEntity(key: string) {
  let applied_filter_entity = JSON.parse(localStorage.getItem(key) || '[]');
  let applied_filter_count = applied_filter_entity.length ? applied_filter_entity.length : null;

  return { applied_filter_entity, applied_filter_count };
}

export function getAppliedFilters(key: string) {
  let applied_filter = '';
  let filters = JSON.parse(localStorage.getItem(key) || '[]');
  let applied_filter_count = filters.length ? filters.length : null;

  if (filters.length) {
    filters.forEach(element => {
      Object.keys(element).forEach(key => {
        applied_filter += `${key}=${element[key]}&`
      })
    });
  }

  return { applied_filter, applied_filter_count };
}

export function viewDocument(file: string) {
  if (!file) {
    this._toasterService.showError('ERROR', 'Invalid file path');
    return;
  };
  file = `${environment.fileBaseUrl}${file.replaceAll(' ', '+')}`;
  window.open(file);
}

export function validateUploadDoc(module: string, size?: number) {
  let file_size;
  let file_size_invalid;
  let file_accept;
  let file_not_allowed;

  let restrictions = JSON.parse(localStorage.getItem('upload_restrictions'));
  if (restrictions?.length) {
    let find_module = restrictions.find(element => element.module === module);
    if (find_module) {
      file_size = size ? fileSizeAndExtension(size).size : '';
      file_size_invalid = ((size / (1024 * 1024)) >= find_module.size);
      file_accept = find_module?.extension?.toString();
    } else {
      file_not_allowed = true;
    }
  }

  return { file_size, file_size_invalid, file_accept, file_not_allowed };
}

export function fileSizeAndExtension(size: number, file_name?: string) {
  let file_size = (size / (1024 * 1024));

  return { size: `${file_size.toFixed(2)} MB`, extension: file_name ? `.${file_name.split('.').pop()}` : '' };
}

export function specificUserParams(is_tenant?: boolean) {
  let user_details = JSON.parse(localStorage.getItem('roles_permissions'));
  let query: string = '';

  if (user_details?.length) {
    let params = user_details[0];
    if (params?.tenants?.length && !is_tenant) {
      query += `tenant=${params?.tenants[0]?.id}&`;
    }
    if (params?.subTenant?.length) {
      query += `subtenant=${params?.subTenant}&`;
    }
    if (params?.displayUsers?.length) {
      query += `displayUsers=${params?.displayUsers}&`;
    }
  }

  return query;
}

export function getMailReplyFormat(data: any): any {
  let replyBody = `
    <p></p>
    <hr style="display:inline-block;width:98%" tabindex="-1">
    <p dir="ltr" style="font-size: 12px;">
      <strong>From:</strong> ${data?.from_name} &lt;${data?.from_mail}&gt;<br>
      <strong>Sent:</strong> ${data?.createdAt}<br>
      <strong>To:</strong> ${data?.to_name} &lt;${data?.to_mail}&gt;<br>
      ${getCC(data?.cc_mail)}
      ${getCC(data?.bcc_mail)}
      <strong>Subject:</strong> ${data?.subject ?? ''}
    </p>
    <br>
    <p>
      ${data?.body}
    </p>`

  return replyBody;
}

export function getCC(cc) {
  if (cc) {
    return `<strong>Cc:</strong> &lt;${cc ?? ''}&gt;<br>`
  } else {
    return ''
  }
}

export function getBCC(bcc) {
  if (bcc) {
    return `<strong>Cc:</strong> &lt;${bcc ?? ''}&gt;<br>`
  } else {
    return ''
  }
}

export function userParams(module: string, is_tenant?: boolean) {
  let query = '';

  if (this._permissionPipe.transform(module, permissionEntity.access.read_all)) {
    query += `displayType=readAll&`;
  } else {
    query += specificUserParams(is_tenant);
    query += `displayType=read&`;
  }
  return query;
}

export function fileLogo(file_name: string) {
  file_name = file_name?.toLowerCase();
  let extension = file_name.split('.').pop();
  let final_url = '';
  switch(extension) {
    case 'png':
      final_url = 'png.png';
      break;

    case 'jpg':
      final_url = 'jpg.png'
      break;
    
    case 'jpeg':
      final_url = 'jpeg.png'
      break;

    case 'pdf':
      final_url = 'pdf2.png'
      break;

    case 'txt':
      final_url = 'txt.png'
      break;

    case 'doc':
      final_url = 'doc.png'
      break;

    case 'xl4':
        final_url = 'xl4.png'
        break;

    case 'xlsx':
      final_url = 'xlsx.png'
      break;
  }

  return final_url;
}

export function filePathWithBaseURL(file: string) {
  file = `${environment.fileBaseUrl}${file?.replaceAll(' ', '+')}`;
  return file;
}

export function fileType(file_name?: string) {
  let type = file_name.split('.').pop();
  let img_type = ['jpg', 'jpeg', 'png', 'svg'];
  return img_type.includes(type);
}

export function getMailDraftEditBody(data: any): any {
  let replyBody = `
      ${data?.mail_details?.body}
    `

  return replyBody;
}


export function calculateSize(file: any) {
  let total_byte = 0;
  let size_in_mb = 0;
  for(let val of file) {
    total_byte += val.size;
  }
  if (total_byte) {
    size_in_mb = (total_byte / (1024 * 1024));
  }

  return size_in_mb;
}

export function random3DigitId() {
  return Math.floor(100 + Math.random() * 900);
}

export function decodeHtml(html: string) {
  // Create a temporary DOM element to decode HTML entities
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}