export const environment = {
    production: false,
    // apiURL: 'http://192.168.0.27:5000/',
    apiURL: 'https://api-dev.crizac.com/',
    fileBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseUrl: 'https://www.crizac.co.uk/yeasterdownload/',
    zegoCloud: 'https://connect.crizac.com/',
    apiPHPURL: 'https://accounts.crizac.com/',
    fileDownloadUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com'
};
