<div class="container">
    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
        <div class="row jumbotron">
            <div class="col-sm-12 mx-t3 mb-4">
                <h2 class="text-center sty">Agent Sign-Up</h2>
                <h3 class="mb-lg-4 mb-4">
                    <legend>Your Personal Details</legend>
                </h3>
            </div>
            <div class="col-sm-6 form-group mt-3">
                <label for="firstName">First Name <span class="color-orange">*</span></label>
                <input type="text" class="form-control" placeholder="Enter your first name." formControlName="firstName"
                    required>
            </div>
            <div class="col-sm-6 form-group mt-3">
                <label for="lastName">Last name <span class="color-orange">*</span></label>
                <input type="text" class="form-control" name="lastName" formControlName="lastName" placeholder="Enter your last name."
                    required>
            </div>

            <div class="col-sm-6 form-group mt-3">
                <label for="email">E-mail <span class="emailNote">(Note: Commission Details And Finance Will Be Sent To This Mail Id)</span><span class="color-orange">*</span></label>
                <input type="email" class="form-control" name="email" formControlName="email" placeholder="Enter your email." required>
            </div>
            <div class="col-sm-6 form-group mt-3">
                <label for="confirm-email">Confirm E-Mail <span class="color-orange">*</span></label>
                <input type="email" class="form-control" name="confirm-email" formControlName="confirmEmail" placeholder="Confirm E-Mail" >
                <div class="sty" *ngIf="registrationForm.get('confirmEmail').hasError('emailMismatch') && (registrationForm.get('confirmEmail').dirty || registrationForm.get('confirmEmail').touched)">
                    Email addresses do not match.
                </div>
            </div>

            <div class="col-sm-6 form-group mt-3">
                <label for="mobile">Mobile No <span class="color-orange">*</span></label>
                <input type="number" class="form-control" name="mobile" formControlName="mobile" placeholder="9999999999" required>
            </div>

            <div class="col-sm-6 form-group mt-3">
                <label for="whatsappNumber">Whatsapp No</label>
                <input type="number" class="form-control" name="whatsappNumber" formControlName="whatsappNumber" placeholder="Whatsapp No"
                    >
            </div>
            <div class="col-sm-6 form-group mt-3">
                <label class="label">Do You Want All The Applications Update To This E-Mail ID? <span
                        class="color-orange">*</span></label>
            </div>

            <div class="col-sm-6 form-group mt-3">
                <input class="form-group" type="radio" name="yes" 
                    style="height: 17px;">
                <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                </label>
                <input class="form-group" type="radio" name="no" 
                    style="height: 17px;">
                <label class="form-check-label" for="flexRadioDefault1">
                    No
                </label>
            </div>


            <div class="col-lg-12">
                <h3 class="mt-lg-5 mb-lg-4 mb-4">Company Details</h3>
            </div>


            <div class="col-sm-4 form-group mt-3">
                <label for="company">Company <span class="color-orange">*</span></label>
                <input type="text" class="form-control" name="company" formControlName="company" placeholder="Company" required>
            </div>

            <div class="col-sm-4 form-group mt-3">
                <label for="webSite">Website</label>
                <input type="text" class="form-control" name="webSite" formControlName="webSite" placeholder="Website">
            </div>

            <div class="col-sm-4 form-group mt-3">
                <label for="address1">Address 1 <span class="color-orange">*</span></label>
                <input type="text" class="form-control" name="address1" formControlName="address1" placeholder="Address" required>
            </div>
            <div class="col-sm-3 form-group mt-3">
                <label for="country">Country<span class="color-orange">*</span></label>
                <select class="form-control custom-select browser-default" [(ngModel)]="selectedCountry" (change)="onCountryChange()" formControlName="country" required>
                    <!-- <option value="select">Select</option> -->
                    <option *ngFor="let country of country" [ngValue]="country">{{ country?.country }}</option>
                </select>
            </div>


            <div class="col-sm-3 form-group mt-3">
                <label for="state">State<span class="color-orange">*</span></label>
                <select class="form-control custom-select browser-default" [(ngModel)]="selectedState" (change)="onStateChange()" formControlName="state" required>
                    <!-- <option value="select">Select</option> -->
                    <option *ngFor="let state of states" [ngValue]="state">{{ state?.name }}</option>
                </select>
            </div>

            <div class="col-sm-3 form-group mt-3">
                <label for="city">City<span class="color-orange">*</span></label>
                <select class="form-control custom-select browser-default" [(ngModel)]="selectedCity" formControlName="city" required>
                    <!-- <option value="select">Select</option> -->
                    <option *ngFor="let city of cities" [ngValue]="city">{{ city }}</option>
                </select>
            </div>

            <div class="col-sm-3 form-group mt-3">
                <label for="postcode">Pincode <span class="color-orange">*</span></label>
                <input type="number" class="form-control" name="postcode" formControlName="postcode" placeholder="Enter your state name."
                    required>
            </div>
            <!-- <div class="col-lg-12">
                <h3 class="mt-lg-5 mb-lg-4 mb-4"> Your Password</h3>
            </div>

            <div class="col-sm-6 form-group mt-3">
                <label for="password">Enter Password<span class="color-orange">*</span></label>
                <input type="password" name="password" class="form-control" formControlName="password" placeholder="Enter your password." required>
            </div>
            <div class="col-sm-6 form-group mt-3">
                <label for="pass2">Confirm Password<span class="color-orange">*</span></label>
                <input type="password" name="confirmPassword" class="form-control" formControlName="confirmPassword" placeholder="Re-enter your password." required>
                <div *ngIf="registrationForm.get('confirmPassword').hasError('passwordMismatch') && (registrationForm.get('confirmPassword').dirty || registrationForm.get('confirmPassword').touched)">
                    Passwords do not match.
                </div>
            </div> -->
            
            <fieldset >
                <legend class="mt-4">Captcha</legend>
                <div class="mb-3 ">
                    <label for="input-captcha" class="form-label">Enter the code in the box below</label> <input
                        type="text" name="captcha" value=""  class="form-control mb-1">
                    <div id="captcha">
                        <img src="index.php?route=extension/opencart/captcha/basic|captcha" alt="">
                    </div>
                    <div id="error-captcha" class="invalid-feedback"></div>
                </div>
            </fieldset>
            <div class="d-inline-block pt-2 pd-2 w-100">
                <div class="float-end text-right">
                    <button type="submit" class="btn ss-btn-get-join" id="register-btn" name="submit" [disabled]="!registrationForm?.valid">Register</button>
                </div>
            </div>
        </div>
    </form>
</div>