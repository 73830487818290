<ng-container *ngIf="image; else no_img">
    <div class="text-center p-20">
        <img [src]="image" alt="" width="80%">
    </div>
</ng-container>
<ng-template #no_img>
    <ngx-doc-viewer 
        *ngIf="file_url"
        [url]="file_url" 
        viewer="google" 
        style="width:100%; height: 100vh;" 
        (loaded)="contentLoaded()"
        [googleCheckContentLoaded]="true"
        [googleCheckInterval]="3000"
        [googleMaxChecks]="5"
        disableContent='none'>
    </ngx-doc-viewer>
</ng-template>


