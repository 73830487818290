import { Injectable } from '@angular/core';
import { HttpClientService } from '../httpClient/httpClient.service';
import { Observable } from 'rxjs';
import { agentsApi } from '../../constants/agents-api';
import { PaginationType } from '../../interfaces/pagination/pagination-type';
import { permissionEntity } from '../../constants/permission';
import { PermissionsPipe } from '../../pipe/permissions.pipe';
import { specificUserParams, userParams } from '../../helpers/helperfunc';

@Injectable({
    providedIn: 'root'
})
export class AgentsService {
    permissions_entity = permissionEntity;

    constructor(
        private httpClientService: HttpClientService,
        private _permissionPipe: PermissionsPipe // dont remove this
    ) { }

    getAgents(pagination: PaginationType, filters?: any, search?: string, tenant_id?: any, sort: any = null): Observable<any> {
        let query = '?';
        if (filters) {
            query += filters;
        }
        if (tenant_id) {
            query += `tenantId=${tenant_id}&`;
        }
        if (search) {
            query += `company=${search}&`;
        }
        if (sort) {
            query += `sortData=${sort}&`;
        }
        query += userParams.call(this, this.permissions_entity.modules.agents);
        query += `skip=${pagination.skip}&limit=${pagination.limit}`;

        return this.httpClientService.get(`${agentsApi.getAgents}${query}`);
    }

    getAllAgents(): Observable<any> {
        return this.httpClientService.get(`${agentsApi.getAgents}`);
    }

    postAgents(payload: any): Observable<any> {
        return this.httpClientService.post(
            agentsApi.createAgents,
            payload
        );
    }

    editAgents(payload: any, agentsId: string): Observable<any> {
        const url = `${agentsApi.editAgents}?id=${agentsId}`;
        return this.httpClientService.put(url, payload);
    }

    getEditAgent(agentsId: string): Observable<any> {
        const url = `${agentsApi.getAgents}/details?Id=${agentsId}`;
        return this.httpClientService.get(url);
    }

    deleteAgents(): Observable<any> {
        return this.httpClientService.delete(agentsApi.deleteAgents);
    }

    agentApproval(payload: any): Observable<any> {
        return this.httpClientService.post(
            agentsApi.approveAgents,
            payload
        );
    }

    resendInvite(id: string): Observable<any> {
        return this.httpClientService.post(agentsApi.resendInvite.replace(':userId', id), {});
    }

    deleteAgent(id: string, status: any): Observable<any> {
        const url = `${agentsApi.deleteAgents}?status=${status}&id=${id}`;
        return this.httpClientService.delete(url);
    }

    getAppliedFilters() {
        let applied_filter = JSON.parse(localStorage.getItem('agents_applied_filter') || '[]');
        let applied_filter_count = applied_filter.length ? applied_filter.length : null;

        return { applied_filter, applied_filter_count };
    }

    getAgentUploadDoc(pagination: PaginationType, agent_id: any): Observable<any> {
        return this.httpClientService.get(`${agentsApi.getAgentDoc}${agent_id}&skip=${pagination.skip}&limit=${pagination.limit}`);
    }

    uploadDoc(agent_id: any, payload: any) {
        const url = `${agentsApi.uploadAgentDoc}${agent_id}`;
        return this.httpClientService.upload(url, payload);
    }

    getAgentUploadDocById(pagination: PaginationType, agentId: string): Observable<any> {
        return this.httpClientService.get(`${agentsApi.getAgentDoc}${agentId}&skip=${pagination.skip}&limit=${pagination.limit}`);
    }

    uploadDocById(id: any, payload: any) {
        const url = `${agentsApi.uploadAgentDoc}${id}`;
        return this.httpClientService.upload(url, payload);
    }

    getAgentLogs(pagination: PaginationType, agent_id: any): Observable<any> {
        return this.httpClientService.get(`${agentsApi.getAgentLogs}${agent_id}&skip=${pagination.skip}&limit=${pagination.limit}`);
    }

    approvePayment(paymentID, status, agentId): Observable<any> {
        return this.httpClientService.post(`${agentsApi.agentBankDetails}${paymentID}${status?`&status=${status}`:''}${agentId?`&agentId=${agentId}`:''}`,{});
    }

    getBankdetailsList(agentId: any, payload: any): Observable<any> {
        return this.httpClientService.post(`${agentsApi.agentBankDetails}${agentId}`, payload);
    }

    // Approved Agent, Non Approved, Pending Bank, Inactive API Service
    getAgentsApproved(pagination: PaginationType, filters?: any, search?: string, tenant_id?: any, sort: any = null): Observable<any> {
        // console.log(sort);
        let query = '?';
        if (filters) {
            query += filters;
        }
        if (tenant_id) {
            query += `tenantId=${tenant_id}&`;
        }
        if (search) {
            query += `search=${search}&`;
        }
        if (sort) {
            query += `sortData=${sort}&`;
        }
        query += userParams.call(this, this.permissions_entity.modules.agents);
        query += `skip=${pagination.skip}&limit=${pagination.limit}`;
        // console.log(query);

        return this.httpClientService.get(`${agentsApi.getAgents}${query}`);
    }
}
