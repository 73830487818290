import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filePathWithBaseURL, fileType } from 'src/app/core/helpers/helperfunc';
import { LoaderService } from 'src/app/core/services/loader/loader.service';

@Component({
  selector: 'app-doc-viewers',
  templateUrl: './doc-viewers.component.html',
  styleUrls: ['./doc-viewers.component.scss']
})
export class DocViewersComponent implements OnInit {
  image: any;
  // base_url = 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system/upload/August-September-October-November 2024/717446/1723442617-NF2RP9VACZ8UPDXL5657.ETicket.pdf';
  file_url: string = '';

  constructor(
    private _loaderService: LoaderService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(res => {
      if (Object.keys(res).length) {
        if (fileType(res['url'])) { 
          this.image = filePathWithBaseURL(res['url']);
        } else {
          this._loaderService.showLoader();
          this.file_url = filePathWithBaseURL(res['url']) ;
        }
      }
    })
  }

  contentLoaded() {
    this._loaderService.hideLoader();
  }
}
